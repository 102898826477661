import React from 'react'
import { Route } from 'react-router-dom'
import ViewActionAttachment from '../components/ViewActionAttachment'
import ViewDoc from '../components/ViewDoc'
import ViewSupDoc from '../components/ViewSupDoc'
import ViewWaActionAttachment from '../components/ViewWaActionAttachment'

export default function Routes() {
    return (
        <>
            <Route path='/view-doc/:url/:name/:docCode/:docName' component={ViewDoc} />
            <Route path='/view-supdoc/:url/:filename' component={ViewSupDoc} />
            <Route path='/actions/attachment/:url/:filename' component={ViewActionAttachment} />
            <Route path='/actions-wa-attachment/:url/' component={ViewWaActionAttachment} />
        </>
    )
}
