import { BrowserRouter, Switch } from "react-router-dom";
import Routes from "./routes/Routes";
import 'fontsource-roboto';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Switch>
            <Routes />
          </Switch>
        </BrowserRouter>
    </div>
  );
}

export default App;
