import { Button, Container, Grid, Typography } from '@material-ui/core'
import { FileCopyOutlined } from '@material-ui/icons'
import React from 'react'
import { useParams } from 'react-router-dom'
import logo from '../img/ISO.png'


export default function ViewWaActionAttachment() {
    const { url } = useParams()
    const decodedUrl = decodeURIComponent(url)
    
    return (
        <div>
            <Container>
                <Grid 
                    container 
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid item xs={3}>
                        <div style={{ textAlign: 'center'}}>
                            <Typography>ISOMS Action Attachment:</Typography>
                            
                            <div style={{ clear: 'both', paddingTop: 20, paddingBottom: 20, textAlign: 'center'}}>
                                
                                    <FileCopyOutlined color="primary" style={{ fontSize: 50 }} />
                                
                            </div>
                            <a href={decodedUrl} target="_blank" rel="noopener noreferrer">
                                <Button color="primary" variant="contained">Download File</Button>
                            </a>
                            <div style={{ marginBottom: 50 }} />
                            <p className="iso-small">This file was shared using ISOMS</p>
                            <a href="https://iso-office.co.za" rel="noopener noreferrer" target="_blank">
                                <img src={logo} width="80" />
                            </a>
                        </div>
                    </Grid>   
                </Grid>
            </Container>
        </div>
    )
}
